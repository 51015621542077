<template>
  <div class="yunvideo">
    <!-- wrap -->
    <div class="wrap w clearfix"
         v-html="contentData">

    </div>
  </div>
</template>

<script>
import { getCloudNoticeListData } from "@/api/index";

export default {
  data () {
    return {
      contentData: ''
    };
  },
  mounted () {

  },
  watch: {
    $route: {
      handler () {
        console.log(this.$route.query.type, 3333333)
        this.initDateContent();
      },
      deep: true
    }
  },
  created () {
    this.initDateContent();

  },
  methods: {
    initDateContent () {
      let str = ''
      if (this.$route.query.type == 3) {
        str = 'zhdt'
      } else if (this.$route.query.type == 2) {
        str = 'zgfb'
      } else if (this.$route.query.type == 1) {
        str = 'zgjg'
      } else if (this.$route.query.type == 4) {
        str = 'jtxx'
      }
      const datas = {
        key: str
      }
      getCloudNoticeListData(datas).then(item => {
        this.contentData = item.data.value
      })
    }
  }
};
</script>

<style scoped  lang="scss">
.clearfix {
  padding: 65px;
  line-height: 32px;
}
.wrap {
  margin-bottom: 243px;
  box-sizing: border-box;

  h5 {
    padding-top: 67px;
    padding-bottom: 53px;
    box-sizing: border-box;
    color: #565656;
    line-height: 33px;
    font-size: 24px;
    text-align: center;
  }
  p {
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-bottom: 20px;
  }

  img {
    margin-bottom: 20px;
  }
  /deep/ img {
    max-width: 100%;
  }
}
</style>